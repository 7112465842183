import React, {useEffect, useState} from "react";
import {DatePicker, Form, FormInstance} from "antd";
import {IAppComponentProps} from "../../../components";
import {
    ExternalStateUpdateCallback,
    Initialization,
    LabelLocation,
    OnChangeListener,
    Validator
} from "../InfoPortalInterfaces";
import {getRangePickerLocalization} from "./grid/CalendarUtils";
import dayjs, {Dayjs} from "dayjs";
import Button from "antd/lib/button";

type FromToDateSelectionProperties = {
    initialization: Initialization,
    form: FormInstance<any>,
    externalStateUpdater?: ExternalStateUpdateCallback<any>,
    onFromDateChange?: OnChangeListener<Dayjs>,
    onToDateChange?: OnChangeListener<Dayjs>,
    validator?: Validator,
    initialValue?: Dayjs[],
    dateFormatter?: string,
    labelLocation?: LabelLocation,
    displayColon?: boolean,
    customHeading?: string

} & IAppComponentProps;

type BaseSelectionProperties = {
    fieldName: string,
    singleHeadingName: string,
    fromHeading: string,
    toHeading: string,
    properties: FromToDateSelectionProperties
};

function FromToDateSelection(
    {
        fromHeading,
        fieldName,
        singleHeadingName,
        toHeading,
        properties: {
            initialization,
            form,
            externalStateUpdater,
            onToDateChange,
            onFromDateChange,
            intl,
            validator,
            initialValue,
            dateFormatter,
            labelLocation,
            displayColon,
            customHeading
        }
    }: BaseSelectionProperties) {
    const [_, rerender] = useState<boolean>(false);
    const [fromDate, setFrom] = useState<Dayjs>(null);
    const [toDate, setTo] = useState<Dayjs>(null);
    const {RangePicker} = DatePicker;
    const forceRerender = () => rerender(current => !current);
    const [open, setOpen] = useState(false);

    const setTodayDate = () => {
        const today = dayjs();
        form.setFieldsValue({
            [fieldName]: [today, today],
        });
        setOpen(false);
    };

    const selectedFromDate = (dayjs: Dayjs) => {
        setFrom(dayjs);
        onFromDateChange?.performAction(dayjs);
    };

    const selectedToDate = (dayjs: Dayjs) => {
        setTo(dayjs);
        onToDateChange?.performAction(dayjs);
    };

    const setInitialValues = () => {
        if (initialValue) {
            if (!form.getFieldValue(fieldName)) {
                const initialValues = {};
                initialValues[fieldName] = initialValue;
                form.setFieldsValue(initialValues);
            }
        }
    };
    const dateRangeChanged = (dateRange: [Dayjs, Dayjs]) => {
        selectedFromDate(dateRange && dateRange.length > 0 ? dateRange[0] : undefined);
        selectedToDate(dateRange && dateRange.length > 0 ? dateRange[1] : undefined);
    };

    useEffect(() => {
        externalStateUpdater?.register({
            performAction() {
                forceRerender();
            }
        });
        setInitialValues();
    }, []);

    function isToBeforeFrom(fromDate: Dayjs, toDate: Dayjs) {
        if (fromDate && toDate) {
            if (toDate.startOf("d").isBefore(fromDate.startOf("d"))) {
                return true;
            }
        }
        return false;
    }

    const getDateFormat = () => dateFormatter || "YYYY-MM-DD";

    const getLabel = () => {
        return customHeading || fromHeading;
    }

    const displayCorrectFormatOnHint = (heading: string) => {
        const language = localStorage.getItem("locale");

        if (language === "en") {
            return heading.replace("YYYY-MM-DD", getDateFormat());
        } else if (language === "de") {
            const germanFormat = getDateFormat().replaceAll("Y", "J").replaceAll("D", "T")
            return heading.replace("JJJJ-MM-TT", germanFormat);
        }

        return heading;
    }

    return <div>

        <Form.Item label={getLabel()}
                   className={"from-input"}
                   name={fieldName}
                   rules={[
                       {
                           required: validator?.isMandatory(fieldName),
                           message: intl.formatMessage({
                               id: "generic-requiredField-message",
                               defaultMessage: `Please Provide Required Field`
                           })
                       },
                       ({getFieldValue}) => ({
                           validator(_, value) {
                               if (value?.length > 1 && isToBeforeFrom(value[0], value[1])) {
                                   return Promise.reject(new Error("From date cannot be after to date"));
                               }
                               return Promise.resolve();
                           }
                       })
                   ]}>
            <RangePicker data-testid={`from-selection-${fieldName}`} allowClear={true} format={getDateFormat()}
            open={open}
            onOpenChange={(status) => setOpen(status)}
            renderExtraFooter={() => (
                <Button type="primary" onClick={setTodayDate} style={{ marginLeft:'240px', marginTop: '10px', marginBottom: '10px' }}>
                    Today
                </Button>
            )}
                         placeholder={[displayCorrectFormatOnHint(intl.formatMessage({
                             id: "info-portal-range-picker-start-date-hint",
                             defaultMessage: `Start Date YYYY-MM-DD`
                         })),
                             displayCorrectFormatOnHint(intl.formatMessage({
                                 id: "info-portal-range-picker-end-date-hint",
                                 defaultMessage: `End Date YYYY-MM-DD`
                             }))]}
                         disabledDate={(current) => current.isAfter(dayjs())}
                         style={{width: "100%"}} onChange={dateRangeChanged}
                         locale={getRangePickerLocalization(intl, localStorage.getItem("locale") || "en")}

            />
        </Form.Item>


    </div>;
}

export function GRSCDateFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"grScDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-grscDate"})}
        fromHeading={props.intl.formatMessage({id: "grid-heading-grscDate"})}
        toHeading={props.intl.formatMessage({id: "grid-heading-grscDate"})}/>;
}

export function DocumentDateFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"documentDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-documentDate"})}
        fromHeading={props.intl.formatMessage({
            id: "grid-heading-documentDate",
            defaultMessage: "Document Date"
        })}
        toHeading={props.intl.formatMessage({id: "info-portal-document-to-date", defaultMessage: "Document Date"})}/>;
}

export function DeliveryNoteFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"deliveryNoteDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-deliveryNoteDate"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-delivery-note-from-date"})}
        toHeading={props.intl.formatMessage({id: "info-portal-delivery-note-to-date"})}/>;
}

export function PurchaseOrderFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"purchaseOrderDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-purchaseOrderDate"})}
        fromHeading={props.intl.formatMessage({id: "grid-heading-purchaseOrderDate"})}
        toHeading={props.intl.formatMessage({id: "info-portal-purchase-order-to-date"})}/>;
}

export function PaymentFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"paymentDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-paymentDate"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-payment-date-from"})}
        toHeading={props.intl.formatMessage({id: "info-portal-payment-date-to"})}/>;
}

export function PostingDateOfDeliveryFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"postingDeliveryDate"}
        singleHeadingName={props.intl.formatMessage({id: "info-portal-posting-delivery-date-from"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-posting-delivery-date-from"})}
        toHeading={props.intl.formatMessage({id: "info-portal-posting-delivery-date-to"})}/>;
}

export function ChangedOnFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"changedOnDate"}
        singleHeadingName={props.intl.formatMessage({id: "info-portal-changed-on-from"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-changed-on-from"})}
        toHeading={props.intl.formatMessage({id: "info-portal-changed-on-to"})}/>;
}

export function DistributionFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"distributionDate"}
        singleHeadingName={props.intl.formatMessage({id: "info-portal-distribution-date-from"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-distribution-date-from"})}
        toHeading={props.intl.formatMessage({id: "info-portal-distribution-date-to"})}/>;
}

export function CreatedFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"createdDate"}
        singleHeadingName={props.intl.formatMessage({id: "info-portal-creation-date-from"})}
        fromHeading={props.intl.formatMessage({id: "info-portal-creation-date-from"})}
        toHeading={props.intl.formatMessage({id: "info-portal-creation-date-to"})}/>;
}

export function RejectionDateFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"rejectionDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-rejectionDate"})}
        fromHeading={props.intl.formatMessage({id: "grid-heading-rejectionDate"})}
        toHeading={props.intl.formatMessage({id: "grid-heading-rejectionDate"})}/>;
}

export function ExecutionDateFromToSelection(props: FromToDateSelectionProperties) {
    return <FromToDateSelection
        properties={props}
        fieldName={"executionDate"}
        singleHeadingName={props.intl.formatMessage({id: "grid-heading-executionDate"})}
        fromHeading={props.intl.formatMessage({id: "grid-heading-executionDate"})}
        toHeading={props.intl.formatMessage({id: "grid-heading-executionDate"})}/>;
}